import React, { useEffect, useRef, useState } from 'react';
import * as otherServices from '../services/common.services';
import { useLocation, useNavigate } from 'react-router-dom';
import {attachDynamicStrings, getLocalKey, getUser, setTitle} from '../../../utils/commonfunction'
import { STAFF_ROLE } from '../../../utils/Constants';
import { useSelector } from 'react-redux';
const Home = () => {
    const navigate = useNavigate();
    const [bannerData, setBannerData] = useState([]);
    const loc = useLocation();
    const [homeData, setHomeData] = useState();
    const homeSliderRef = useRef();

  useEffect(() => {
    otherServices.cmsBanner().then((response) => {
      setBannerData(response?.data?.list);
    }).catch((err) => {
      console.log("err", err);
    })
  }, []);
  
  /** trigger the home slider start event */
  useEffect(() => {
    if(homeSliderRef.current){
      setTimeout(() => {
        const event = new CustomEvent("startSlider");
        document.dispatchEvent(event);
      }, 100);
    }
  }, [homeSliderRef, bannerData])


  useEffect(() => {
    otherServices.cmsDetails("home_page_content").then((response) => {
      setHomeData(attachDynamicStrings(response?.data?.description));
      setTitle(response?.data?.title);
    }).catch((error) => {
        console.log("error in terms & conditions",error);
    })
  }, [loc]);

  function acceptDualLeadCoordinator(){
    const flag = getLocalKey('boothAttendantsAsLeadCoordinator');
    if(flag && flag === "true" || flag && flag === true){
      return true;
    }else{
      return false;
    }
  }

  useEffect(() => {
    const userData = getUser();
    if (userData && Object.keys(userData).length) {
      if (userData.role === STAFF_ROLE.LM_BACK_OFFICE) {
        navigate(`/back-office/home`);
      } else if (userData.role === STAFF_ROLE.LEAD_COORDINATOR) {
        navigate(`/lead-coordinator/home`);
      } else if (userData.role === STAFF_ROLE.BOOTH_ATTENDANTS) {
        if(acceptDualLeadCoordinator()) {
          navigate(`/lead-coordinator/home`);
        } else {
          navigate(`/sis-user/home`);
        }
      } else if (userData.role === STAFF_ROLE.INFO_COUNTER) {
        navigate(`/info-counter/home`);
      }
    }
  }, []);
  
    return (
    <>
      <div className="wraper-inner cpt">
        <div className="owl-carousel owl-theme" ref={homeSliderRef}>
          {bannerData && bannerData.map((item, index) => (
            <div className="item" key={index}>
              <section
                className="banner-sec"
                style={{
                  background: `url("${item?.image}") no-repeat center center`
                }}>
                <div className="container">
                  <div className="row ">
                    <div className="col-md-8 m-auto pt-5 banner-sec-content">
                      <h1>{item?.title}</h1>
                      <div dangerouslySetInnerHTML={{ __html: `${item?.description ? item?.description : "N/A"}` }}>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          ))}
        </div>
        <div dangerouslySetInnerHTML={{ __html: `${homeData ? homeData : ""}` }}></div>
      </div>
    </>
  )
}

export default Home