import React, { useEffect, useState, useRef } from "react";
import { Formik } from "formik";
import * as eventServices from "../../services/events.services";
import Swal from "sweetalert2";
import { SWAL_SETTINGS } from "../../../../utils/Constants";
import {
  handleServerValidations,
  globalLoader,
  formateDate,
  addSpecialCharacter,
  removeCommas,
  writeMyExcel,
  TrimText,
  formateDateWithMonth,
  SpecialCharacter,
  capitalizeFirstLetterOfEachWord,
  getFileNameFromURL,
} from "../../../../utils/commonfunction";
import { capitalizeFirstLetter } from "../../../../utils/commonfunction";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CustomRangepicker from "../../common/rangepicker";
import Sorting from "../../common/sorting";
import Loader from "../../common/loader";
import CustomPagination from "../../common/custompagination";
import { Button, Modal } from "rsuite";
import moment from "moment";
import QRCode from "react-qr-code";
import ShowAnswer from "../../shared/ShowAnswer";
import VideoPlayer from "../../../user/shared/VideoPlayer";

const ViewTicketDetails = () => {
  const location = useLocation();
  const [showdefault, setShowDefault] = useState(null);
  const [refreshDetails, setRefreshDetails] = useState(0);
  const params = useParams();
  const formikRef = useRef();
  const [csvHeaders, setCsvHeaders] = useState([
    "Date",
    "Salutation",
    "Title",
    "First Name",
    "Middle Name",
    "Last Name",
    "Email Address",
    "Ticket Number",
    "Purpose",
    "Check In",
    "Check Out",
    "Check In Date",
    "Check Out Date",
    "Extra Info - Employee-Code",
    // "Extra Info - Employee-Number",
  ]);
  const [allUsersExportFlag] = useState(false);
  const [search, setSearch] = useState({});
  const [globalsearch, setGlobalSearch] = useState("");
  const [resetdate, setResetDate] = useState(false);
  const [list, setList] = useState([]);
  const [statsupdate, setStatusUpdate] = useState(false);
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(10);
  const [sorting, setSorting] = useState({});
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [attendeessearch, setAttendeesSearch] = useState("");
  const [attendeesList, setAttendeesList] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [numberSearch, setNumberSearch] = useState("");
  const [secondModal, setSecondModal] = useState(false);
  const [ticketModal, setTicketModal] = useState(false);
  const [showCheckIn, setShowCheckIn] = useState(true);
  const [ticketData, setTicketsData] = useState([]);
  const [ticketNumber, setTicketNumber] = useState(null);
  const [AttendessDatalength, setAttendessDatalength] = useState("");
  const [loaderNew, setLoaderNew] = useState(true);
  const [AttendeesItemperpage] = useState(10);
  const [attendeesPage, setAttendeesPage] = useState(1);
  const [query] = useSearchParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  let [videoPlaySource, setVideoPlaySource] = useState(null);
  let [videoSourceType, setVideoSourceType] = useState("video");
  const [exportColumns, setExportColumns] = useState([
    { columnName: "createdAt", columnNameTitle: "Date", checked: true },
    { columnName: "gender", columnNameTitle: "Salutation", checked: true },
    { columnName: "title", columnNameTitle: "Title", checked: true },
    { columnName: "firstName", columnNameTitle: "First Name", checked: true },
    { columnName: "middleName", columnNameTitle: "Middle Name", checked: true },
    { columnName: "lastName", columnNameTitle: "Last Name", checked: true },
    { columnName: "email", columnNameTitle: "Email Address", checked: true },
    { columnName: "ticketNumber", columnNameTitle: "Ticket Number", checked: true },
    { columnName: "purpose", columnNameTitle: "Purpose", checked: true },
    { columnName: "checkin", columnNameTitle: "CheckIn", checked: true },
    { columnName: "checkout", columnNameTitle: "CheckOut", checked: true },
    { columnName: "checkin_date", columnNameTitle: "Check In Date", checked: true },
    { columnName: "checkout_date", columnNameTitle: "Check Out Date", checked: true },
    { columnName: "employee_code", columnNameTitle: "Extra Info - Employee-Code", checked: true },
    // { columnName: "employee_number", columnNameTitle: "Extra Info - Employee-Number", checked: true },
  ]);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    if (search) {
      setLoader(true);
      const formData = new FormData();
      formData.append("page", params.pgno);
      formData.append("per_page", itemperpage);
      formData.append("sort", JSON.stringify(sorting));
      formData.append("search", JSON.stringify(search));
      formData.append("global_search", globalsearch);
      formData.append("event_id", params.id);
      eventServices
        .checkInCheckOutList(formData)
        .then((data) => {
          setDataLength(data.data.total_records);
          setList(data && data.data && data.data.list ? data.data.list : []);
          setPage(data && data.data && data.data.page ? data.data.page : 1);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }, [location, statsupdate, sorting, search, globalsearch, params.id, page]);

  useEffect(() => {
    setLoaderNew(true);
    const formData = new FormData();
    formData.append("page", params.pgno);
    formData.append("per_page", AttendeesItemperpage);
    try {
      formData.append("sort", JSON.stringify(sorting));
      formData.append("search", JSON.stringify(search));
    } catch (err) {
      console.log("Invalid json for sort and search", err);
    }
    if (attendeessearch) {
      formData.append("attendees_search", attendeessearch);
    } else {
      formData.append("number_search", numberSearch);
    }
    formData.append("event_id", params.id);
    formData.append("ticket_number", showdefault?.ticket_number);
    eventServices
      .AttendeesList(formData)
      .then((response) => {
        setAttendessDatalength(response.data.total_records);
        setAttendeesList(
          response && response?.data && response?.data?.list
            ? response.data.list
            : []
        );
        setAttendeesPage(
          response && response.data && response.data.page
            ? response.data.page
            : 1
        );
        setLoaderNew(false);
        if (attendeessearch || numberSearch) {
          setIsSearch(true);
        } else {
          setIsSearch(false);
        }
      })
      .catch((error) => {
        console.log("error ====> ", error);
      });
  }, [attendeessearch, numberSearch]);

  /** Sorting functionality start */
  const handleSort = (e, column) => {
    let sort = { order: 0, column: column };
    if (e.target.classList.contains("assc")) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  };
  /** Sorting functionality end */

  /** Search or filter functionality start */
  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };
  /** Search or filter functionality end */

  /** Reset filter functionality start */
  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    prepareSearch();
    setSearch({});
    setResetDate(!resetdate);
  };
  /** Reset filter functionality end */

  /** Reset filter for modal functionality start */
  const resetFilterNew = (e) => {
    e.preventDefault();
    setAttendeesSearch("");
    setNumberSearch("");
  };
  /** Reset filter for modal functionality end */



  const manageExportReportHeader = () => {
    const chekedHeaders = exportColumns.filter((item) => item.checked);
    const allCheckedColumns = exportColumns.map((col) => {
      return { ...col, checked: true };
    });
    return chekedHeaders.length > 0 ? chekedHeaders : allCheckedColumns;
  }
  /** This function is used to export all check-in-check-out data start */
  const exportAllUsers = (event_id, done) => {
    globalLoader(true);
    const csvHeadersForReport = manageExportReportHeader();
    const formData = new FormData();
    formData.append("page", 1);
    formData.append("per_page", datalength);
    formData.append("sort", JSON.stringify(sorting));
    formData.append("search", JSON.stringify(search));
    formData.append("global_search", globalsearch);
    formData.append("event_id", params?.id);
    eventServices
      .checkInCheckOutList(formData)
      .then((response) => {
        let data = response && response?.data && response?.data?.list ? response.data.list : [];
        let row = data.map((item) => {
          const tempRow = [];
          csvHeadersForReport.forEach((col) => {
            // Check if the column is checked
            if (col.checked) {
              switch (col.columnName) {
                case "createdAt":
                  tempRow.push(formateDate(item?.createdAt));
                  break;
                case "gender":
                  tempRow.push(item?.user_data?.gender || "N/A");
                  break;
                case "title":
                  tempRow.push(item?.user_data?.title || "N/A");
                  break;
                case "firstName":
                  tempRow.push(item?.user_data?.first_name || "N/A");
                  break;
                case "middleName":
                  tempRow.push(item?.user_data?.middle_name || "N/A");
                  break;
                case "lastName":
                  tempRow.push(item?.user_data?.last_name || "N/A");
                  break;
                case "email":
                  tempRow.push(item?.user_data?.email || "N/A");
                  break;
                case "ticketNumber":
                  tempRow.push(item?.ticket_number || "N/A");
                  break;
                case "purpose":
                  tempRow.push(item?.tickets_data?.type || "N/A");
                  break;
                case "checkin":
                  tempRow.push(
                    item && typeof item?.checked_in !== "undefined" && item?.checked_in === true
                      ? "Success"
                      : "Pending"
                  );
                  break;
                case "checkout":
                  tempRow.push(
                    item && typeof item?.checked_out !== "undefined" && item?.checked_out === true
                      ? "Success"
                      : "Pending"
                  );
                  break;
                case "checkin_date":
                  tempRow.push(formateDate(item?.check_in_date));
                  break;
                case "checkout_date":
                  tempRow.push(formateDate(item?.check_out_date));
                  break;
                case "employee_code":
                  tempRow.push(item?.user_data?.extra_info?.employee_code || "N/A");
                  break;
                // case "employee_number":
                //   tempRow.push(item?.user_data?.extra_info?.employee_number || "N/A");
                //   break;
                default:
                  // Handle any unexpected columnName, if needed
                  tempRow.push("N/A");
              }
            }
          });
          const headers = csvHeadersForReport.map(item => item.columnNameTitle);
          const tempData = [...headers];
          setCsvHeaders([...tempData]);

          return tempRow;
        });

        const reportHeaders = csvHeadersForReport.map(item => item.columnNameTitle);
        writeMyExcel(
          [reportHeaders, ...row],
          `Check-in+Check-out-${addSpecialCharacter(
            list[0]?.event_data?.title
          )}.xlsx`
        )
          .then(() => {
            setTimeout(() => {
              setExportColumns((prevState) =>
                prevState.map((col) => ({
                  ...col,
                  checked: true,
                }))
              );
              globalLoader(false);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        console.log("error ====> ", error);
        globalLoader(false);
      });
  };
  /** This function is used to export all check-in-check-out data end */

  /** Check in functionality start */
  const handleCheckIn = () => {
    globalLoader(true);
    setStatusUpdate(false);
    eventServices
      .CheckIn({
        ticket_number: ticketNumber || formikRef.current?.values?.ticket_number,
        event_id: params.id,
      })
      .then((response) => {
        globalLoader(false);
        if (response.success) {
          setTicketNumber(null);
          setRefreshDetails(new Date().getTime());
          setSecondModal(false);
          Swal.fire({
            icon: "success",
            text: response.message,
            ...SWAL_SETTINGS,
          });
          setStatusUpdate(true);
        } else {
          Swal.fire({
            icon: "error",
            text: handleServerValidations(response),
            ...SWAL_SETTINGS,
          });
        }
      })
      .catch((error) => {
        globalLoader(false);
        console.log("error=====>", error);
      });
  };

  const handleCheckInClick = () => {
    handleCheckIn();
  };
  /** Check in functionality end */

  /** Check out functionality start */
  const handleCheckOut = () => {
    globalLoader(true);
    setStatusUpdate(false);
    eventServices
      .CheckOut({
        ticket_number: ticketNumber || formikRef.current?.values?.ticket_number,
        event_id: params.id,
      })
      .then((response) => {
        globalLoader(false);
        if (response.success) {
          setTicketNumber(null);
          setSecondModal(false);
          setRefreshDetails(new Date().getTime());
          Swal.fire({
            icon: "success",
            text: response.message,
            ...SWAL_SETTINGS,
          });
          setStatusUpdate(true);
        } else {
          Swal.fire({
            icon: "error",
            text: handleServerValidations(response),
            ...SWAL_SETTINGS,
          });
        }
      })
      .catch((error) => {
        globalLoader(false);
        console.log("error=====>", error);
      });
  };

  const handleCheckOutClick = () => {
    handleCheckOut();
  };
  /** Check out functionality end */

  /**Ticket details and user functionality start */
  const handleClick = () => {
    setShowCheckIn((data) => true);
    globalLoader(true);
    eventServices
      .TicketDetails({
        ticket_number: ticketNumber || formikRef.current?.values?.ticket_number,
        event_id: params.id,
      })
      .then((response) => {
        globalLoader(false);
        if (response.success) {
          if (response?.data?.check_data) {
            if (
              response?.data?.check_data?.checked_in &&
              response?.data?.check_data?.checked_out == false
            ) {
              setShowCheckIn(false);
            } else {
              setShowCheckIn(true);
            }
          }
          setShowDefault(response.data);
          setSecondModal(true);
        } else {
          Swal.fire({
            icon: "error",
            text: handleServerValidations(response),
            ...SWAL_SETTINGS,
          });
        }
      })
      .catch((error) => {
        globalLoader(false);
        console.log("error=====>", error);
      });
  };

  const setStatus = (status) => {
    if (status === 0) {
      return <span className="badge badge-warning">Pending</span>;
    } else if (status === 1) {
      return <span className="badge badge-success">Active</span>;
    } else if (status === 2) {
      return <span className="badge badge-danger">Cancelled</span>;
    }
  };

  const handleTicketDetails = (data) => {
    eventServices
      .DetailsTickets({
        user_id: data?.user_data?._id,
        user_type: data?.user_type,
        registration_id: data?.registration_data?._id,
      })
      .then((response) => {
        globalLoader(false);
        if (response.success) {
          setTicketsData(response?.data);
          setTicketModal(true);
        } else {
          Swal.fire({
            icon: "error",
            text: handleServerValidations(response),
            ...SWAL_SETTINGS,
          });
        }
      })
      .catch((error) => {
        globalLoader(false);
        console.log("error=====>", error);
      });
  };

  /********** MULTI SELECT >>>>>>********* */

  const selectRecord = (userData, check) => {
    if (check) {
      setTicketNumber(userData?.ticket_number);
    } else {
      setTicketNumber(null);
    }
  };

  const isSelected = (data) => {
    return ticketNumber === data?.ticket_number;
  };
  /********** MULTI SELECT <<<<<<<<<********* */
  const handleTicketNext = (data) => {
    if (ticketNumber) {
      setTicketModal(false);
      handleClick();
    }
  };

  function isJson(data) {
    try {
      JSON.parse(data);
    } catch (e) {
      return false;
    }
    return true;
  }

  const handleQuestionData = (question) => {
    setSelectedQuestion(question);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const playVideo = (video, type) => {
    setVideoSourceType(() => type);
    setVideoPlaySource(video);
  };
  const stopVideoPlayer = () => {
    setVideoSourceType(null);
    setVideoPlaySource(null);
  };

  const handleColumnChange = (e, data) => {
    let stateData = [...exportColumns];
    const index = stateData.findIndex(item => item.columnName === data.columnName);
    stateData[index].checked = e.target.checked;
    console.log("stateData[index]",stateData[index]);
    setExportColumns(stateData);
  };
  return (
    <>
      <Formik
        innerRef={formikRef}
        validateOnChange={true}
        enableReinitialize
        initialValues={{
          ticket_number: "",
        }}
        validate={(values) => {
          const error = {};
          if (!values.ticket_number) {
            error.ticket_number = "Ticket number is required";
          }
          return error;
        }}
        onSubmit={(values) => {
          setRefreshDetails(new Date().getTime());
          setShowDefault(null);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row row-sm">
              <div className="col-lg-12 col-md-12 animation_fade">
                <div className="card custom-card">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div className="row row-sm">
                        {/* Ticket Number */}
                        <div className="col-md-4 text-center form-group">
                          <label
                            htmlFor="ticket_number"
                            className="text-left d-flex"
                          >
                            Ticket Number:<span className="requirestar">*</span>
                          </label>
                          <div className="d-flex align-items-center">
                            <input
                              name="ticket_number"
                              type="text"
                              id="ticket_number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.ticket_number}
                              className="form-control flex-1"
                            />
                            <button
                              className="btn ripple btn-main-primary signbtn ms-3"
                              type="submit"
                              onClick={() => {
                                handleClick();
                              }}
                            >
                              Next
                            </button>
                          </div>
                          <span className="text-danger d-flex text-left">
                            {errors.ticket_number &&
                              touched.ticket_number &&
                              errors.ticket_number}
                          </span>
                        </div>
                        {/* Search by Name */}
                        <div className="col-md-4 text-center form-group">
                          <label
                            htmlFor="ticket_number"
                            className="text-left d-flex"
                          >
                            Search By Name/Email
                            <span className="requirestar"></span>
                          </label>
                          <div className="d-flex align-items-center justify-content-center">
                            <input
                              type="search"
                              className="form-control flex-1"
                              value={attendeessearch}
                              placeholder="Search by name"
                              onChange={(e) => {
                                setAttendeesSearch(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        {/* Search by Phone Number */}
                        <div className="col-md-4 text-center form-group">
                          <label
                            htmlFor="ticket_number"
                            className="text-left d-flex"
                          >
                            Search By Number
                            <span className="requirestar"></span>
                          </label>
                          <div className="d-flex align-items-center justify-content-center">
                            <input
                              type="search"
                              className="form-control flex-1"
                              value={numberSearch}
                              placeholder="Search by number"
                              onChange={(e) => {
                                setNumberSearch(e.target.value);
                              }}
                            />
                            <button
                              type="reset"
                              value="Reset"
                              onClick={resetFilterNew}
                              className="btn btn-warning float-right mr-2 ms-2"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Attendees list data start */}
                {isSearch === true &&
                  attendeesList &&
                  attendeesList.length > 0 ? (
                  <div className="row">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <h6 className="main-content-label">
                        <span></span>
                      </h6>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Type</th>
                            <th>Answer</th>
                            <th className="status_head">Status</th>
                            <th>
                              <div className="sorting_column">
                                <span>Created Date</span>
                              </div>
                            </th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loaderNew ? (
                            <tr>
                              <td colSpan={8}>
                                <Loader />
                              </td>
                            </tr>
                          ) : (
                            <>
                              {attendeesList.length ? (
                                attendeesList.map((row, i) => {
                                  if (row.user_type === "primary") {
                                    return (
                                      <tr key={i}>
                                        <td className="text-capitalize">
                                          {row?.user_data?.name
                                            ? TrimText(row.user_data.name, 20)
                                            : "N/A"}
                                        </td>
                                        <td>
                                          {row?.user_data?.email
                                            ? row.user_data.email
                                            : "N/A"}
                                        </td>
                                        <td>
                                          {row?.user_data?.phone_number
                                            ? isJson(row.user_data.phone_number)
                                              ? JSON.parse(
                                                row.user_data.phone_number
                                              )?.number
                                              : row?.user_data?.phone_number
                                                ?.number
                                            : "N/A"}
                                        </td>
                                        <td className="text-capitalize">
                                          <span className="badge badge-success">
                                            primary
                                          </span>
                                        </td>
                                        <td>
                                          <button
                                            className="btn ripple btn-success"
                                            type="button"
                                            onClick={(e) => {
                                              handleQuestionData(
                                                row?.questions_data
                                              );
                                            }}
                                          >
                                            Show Answer
                                          </button>
                                        </td>
                                        <td>
                                          {typeof row?.registration_data
                                            ?.status !== "undefined"
                                            ? setStatus(
                                              row?.registration_data.status
                                            )
                                            : "N/A"}
                                        </td>
                                        <td>
                                          {row?.createdAt
                                            ? formateDate(row?.createdAt)
                                            : "N/A"}
                                        </td>
                                        <td>
                                          <div className="d-flex">
                                            <button
                                              className="btn ripple btn-success"
                                              type="button"
                                              onClick={(e) => {
                                                handleTicketDetails(row);
                                              }}
                                            >
                                              Manage
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  } else {
                                    return (
                                      <tr key={i}>
                                        <td className="text-capitalize">
                                          {row?.name ? row.name : "N/A"}
                                        </td>
                                        <td>
                                          {row?.email ? row.email : "N/A"}
                                        </td>
                                        <td>
                                          {row?.phone_number
                                            ? isJson(row.phone_number)
                                              ? JSON.parse(row.phone_number)
                                                ?.number
                                              : row?.phone_number?.number
                                            : "N/A"}
                                        </td>
                                        <td>
                                          <span className="badge badge-danger">
                                            Guest
                                          </span>
                                        </td>
                                        <td>
                                          <button
                                            className="btn ripple btn-success"
                                            type="button"
                                            onClick={(e) => {
                                              handleQuestionData(
                                                row?.questions_data
                                              );
                                            }}
                                          >
                                            Show Answer
                                          </button>
                                        </td>
                                        <td>
                                          {typeof row?.registration_data
                                            ?.status !== "undefined"
                                            ? setStatus(
                                              row?.registration_data.status
                                            )
                                            : "N/A"}
                                        </td>
                                        <td>
                                          {row?.createdAt
                                            ? formateDate(row?.createdAt)
                                            : "N/A"}
                                        </td>
                                        <td>
                                          <div className="d-flex">
                                            <button
                                              className="btn ripple btn-success"
                                              type="button"
                                              onClick={(e) => {
                                                handleTicketDetails(row);
                                              }}
                                            >
                                              Manage
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  }
                                })
                              ) : (
                                <tr>
                                  <td colSpan={8} className="text-center">
                                    No records
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div
                      className=""
                      id="example1_info"
                      role="status"
                      aria-live="polite"
                    >
                      <b>
                        Total Records :{" "}
                        {AttendessDatalength ? AttendessDatalength : "0"}
                      </b>
                    </div>
                  </div>
                ) : null}
                {/* Attendees list data end */}
              </div>
            </div>
            {/* Check-in-check-out list data start */}
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h6 className="main-content-label">
                  CheckIn {"&"} CheckOut History
                </h6>
                <div className="d-flex align-items-center">
                  <div className="form-group mb-0 me-3">
                    <div className="form-group mb-0 rangepicker_container filter_design">
                      <i className="fa fa-search calender_icon"></i>
                      <input
                        type="search"
                        className="form-control"
                        value={globalsearch}
                        placeholder="Search"
                        onChange={(e) => {
                          setGlobalSearch(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="me-3">
                    <CustomRangepicker
                      GetDateRange={(e) => {
                        prepareSearch("createdAt", e);
                      }}
                      resetdate={resetdate}
                    />
                  </div>
                  <button
                    type="reset"
                    value="Reset"
                    onClick={resetFilter}
                    className="btn btn-warning float-right mr-2"
                  >
                    Reset Filter
                  </button>
                  {/* {allUsersExportFlag ? (
                    <>
                      <button
                        className="btn ripple btn-main-primary signbtn mr-2"
                        type="button"
                        disabled={datalength === 0}
                        onClick={() => exportAllUsers()}
                      >
                        Export Report
                      </button>
                    </>
                  ) : (
                    <button
                      className="btn ripple btn-main-primary signbtn mr-2"
                      type="button"
                      disabled={datalength === 0}
                      onClick={() => exportAllUsers()}
                    >
                      Export Report
                    </button>
                  )} */}

                  <div className="btn-group position-relative">
                    <button className="btn ripple btn-main-primary signbtn" onClick={exportAllUsers}>Export Report</button>

                    <button
                      type="button"
                      onClick={() => { setDropdownVisible(true) }}
                      className="btn ripple btn-main-primary signbtn dropdown-toggle dropdown-toggle-split"
                    >
                      <i class="fa fa-caret-down" aria-hidden="true"></i>
                    </button>
                    {dropdownVisible && (
                      <>
                        <div className='export-table-columns no-select'>
                          <h6 className='mb-3'>Select columns to export</h6>
                          <ul>
                            {
                              exportColumns.map((item, index) => {
                                return (
                                  <li key={index}>
                                    <label htmlFor={item.columnName} style={{ lineHeight: 'unset' }}
                                      className="ckbox cp d-inline">
                                      <input type="checkbox" id={item.columnName}
                                        checked={item?.checked}
                                        onChange={(e) => { handleColumnChange(e, item) }} />
                                      <span className='no-select'>{item?.columnNameTitle}</span>
                                    </label>
                                  </li>
                                )
                              })
                            }


                          </ul>
                        </div>
                        <span onClick={() => { setDropdownVisible(false) }} className='fixed-overlay-export-columns'></span>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                  <thead>
                    <tr>
                      <th>
                        <div className="sorting_column">
                          <span>Title</span>
                          <Sorting
                            sort={sorting}
                            handleSort={handleSort}
                            column="title"
                          />
                        </div>
                      </th>
                      <th>Email</th>
                      <th>Ticket number</th>
                      <th>CheckIn</th>
                      <th>CheckOut</th>
                      <th>Checkin date</th>
                      <th>Checkout date</th>
                      <th>
                        <div className="sorting_column">
                          <span>Created Date</span>
                          <Sorting
                            sort={sorting}
                            handleSort={handleSort}
                            column="createdAt"
                          />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loader ? (
                      <tr>
                        <td colSpan={8}>
                          <Loader />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {list.length ? (
                          list.map((row, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  {row?.user_data?.name
                                    ? row?.user_data?.name
                                    : "N/A"}
                                </td>
                                <td>
                                  {row?.user_data?.email
                                    ? row?.user_data?.email
                                    : "N/A"}
                                </td>
                                <td>
                                  {row?.ticket_number
                                    ? row?.ticket_number
                                    : "N/A"}
                                </td>
                                <td>
                                  {row &&
                                    typeof row?.checked_in != "undefined" &&
                                    row?.checked_in === true ? (
                                    <span className="badge badge-success">
                                      Success
                                    </span>
                                  ) : (
                                    <span className="badge badge-danger">
                                      Pending
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {row &&
                                    typeof row?.checked_out != "undefined" &&
                                    row?.checked_out === true ? (
                                    <span className="badge badge-success">
                                      Success
                                    </span>
                                  ) : (
                                    <span className="badge badge-danger">
                                      Pending
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {row?.check_in_date
                                    ? formateDate(row?.check_in_date)
                                    : "N/A"}
                                </td>
                                <td>
                                  {row?.check_out_date
                                    ? formateDate(row?.check_out_date)
                                    : "N/A"}
                                </td>
                                <td>
                                  {row?.createdAt
                                    ? formateDate(row?.createdAt)
                                    : "N/A"}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={8} className="text-center">
                              No records
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className=""
                id="example1_info"
                role="status"
                aria-live="polite"
              >
                <b>Total Records : {datalength ? datalength : "0"}</b>
              </div>
              {datalength && datalength > 0 ? (
                <CustomPagination
                  datalength={datalength}
                  itemperpage={itemperpage}
                  currentPage={page}
                  setPage={setPage}
                  pageRoute={[
                    {
                      name: "CheckIn_CheckOut",
                      path: `/admin/event-management/events/view/checkIn-checkOut/${params.id}`,
                    },
                  ]}
                />
              ) : (
                ""
              )}
            </div>
            {/* Check-in-check-out list data end */}
          </form>
        )}
      </Formik>

      {/* Modal for user and tickets data based on the search by ticket number start */}
      <Modal
        size={"lg"}
        className="ticket-details-modal modal-height-auto"
        open={secondModal}
        onClose={() => setSecondModal(false)}
        backdrop={"static"}
      >
        <Modal.Header className="mb-3">
          <Modal.Title>Tickets Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showdefault && Object.keys(showdefault).length > 0 ? (
            <div className="px-3 w-100">
              <div className="row row-sm viewregisration">
                <div className="col-lg-12 col-md-12 animation_fade">
                  {showCheckIn ? (
                    <button
                      className="btn ripple btn-success"
                      type="button"
                      style={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                        zIndex: "2",
                      }}
                      onClick={handleCheckInClick}
                    >
                      Check In
                    </button>
                  ) : (
                    <button
                      className="btn ripple btn-success"
                      type="button"
                      style={{
                        position: "absolute",
                        top: "70px",
                        right: "20px",
                        zIndex: "2",
                      }}
                      onClick={handleCheckOutClick}
                    >
                      Check Out
                    </button>
                  )}

                  <div className="row">
                    <div className="col-lg-12 form-group">
                      <div className="mb-4">
                        <h6 className="main-content-label mb-3">User Info</h6>
                        <p className="mb-1">
                          <label>Name :</label>{" "}
                          {showdefault && showdefault?.user_data?.name
                            ? capitalizeFirstLetter(showdefault.user_data.name)
                            : "N/A"}
                        </p>
                        {showdefault.guest_type === "primary" ? (
                          <p>
                            <label>Email :</label>{" "}
                            {showdefault && showdefault?.user_data?.email ? (
                              <Link
                                to={`/admin/user-management/users/view/${showdefault.user_data._id}`}
                              >
                                {showdefault.user_data?.email}
                              </Link>
                            ) : (
                              "N/A"
                            )}
                          </p>
                        ) : (
                          <p className="mb-1">
                            <label>Email :</label>{" "}
                            {showdefault && showdefault?.user_data?.email
                              ? showdefault.user_data.email
                              : "N/A"}
                          </p>
                        )}
                        <p className="mb-1">
                          <label>Phone Number :</label>{" "}
                          {showdefault &&
                            showdefault?.user_data?.phone_number?.number
                            ? showdefault.user_data?.phone_number.number
                            : "N/A"}
                        </p>
                      </div>
                      <div className="mb-4">
                        <h6 className="main-content-label mb-3">Event Info</h6>
                        <p className="mb-1">
                          <label>Title :</label>{" "}
                          {showdefault && showdefault?.event_data?.title
                            ? capitalizeFirstLetter(
                              showdefault.event_data.title
                            )
                            : "N/A"}
                        </p>
                        <p className="mb-1">
                          <label>Start Date :</label>{" "}
                          {showdefault && showdefault.event_data?.start_date
                            ? formateDate(showdefault.event_data.start_date)
                            : "N/A"}
                        </p>
                        <p className="mb-1">
                          <label>End Date :</label>{" "}
                          {showdefault && showdefault.event_data?.end_date
                            ? formateDate(showdefault.event_data.end_date)
                            : "N/A"}
                        </p>
                      </div>
                      <div className="mb-4">
                        <h6 className="main-content-label mb-3">Ticket Info</h6>
                        <p className="mb-1">
                          <label># :</label>{" "}
                          {showdefault && showdefault?.ticket_number
                            ? showdefault.ticket_number
                            : "N/A"}
                        </p>
                        <p className="mb-1">
                          <label>Title :</label>{" "}
                          {showdefault && showdefault?.title
                            ? capitalizeFirstLetter(showdefault.title)
                            : "N/A"}
                        </p>
                        <p className="mb-1">
                          <label>Type :</label>{" "}
                          {showdefault && showdefault?.type
                            ? capitalizeFirstLetter(showdefault.type)
                            : "N/A"}
                        </p>
                        {showdefault && showdefault?.type === "hotel" ? null : (
                          <p>
                            <label>Guest Type :</label>{" "}
                            {showdefault && showdefault?.guest_type
                              ? capitalizeFirstLetter(showdefault.guest_type)
                              : "N/A"}
                          </p>
                        )}
                        <p className="mb-1">
                          <label>Price :</label>{" "}
                          {showdefault && showdefault?.price
                            ? showdefault?.event_data?.currency?.sign +
                            showdefault.price.toFixed(2)
                            : "0.00"}
                        </p>
                        <p className="mb-1 pb-0">
                          <label>Created Date :</label>{" "}
                          {showdefault && showdefault.createdAt
                            ? formateDate(showdefault.createdAt)
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>
      {/* Modal for user and tickets data based on the search by ticket number end */}

      <Modal
        size="lg"
        className="ticket-modal modal-height-auto"
        open={ticketModal}
        onClose={() => setTicketModal(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Tickets</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100 px-3">
            <div className="row">
              {ticketData && ticketData.length > 0
                ? ticketData.map((ticket, i) => (
                  <div
                    key={i}
                    className="col-lg-6 col-md-6"
                    onClick={() => isSelected(ticket)}
                  >
                    <label
                      className="d-block w-100"
                      htmlFor={"select-checkbox-checkin-" + i}
                    >
                      <div class="border border-1 rounded-3 position-relative">
                        <div class="card-body p-3 cp e_none">
                          <input
                            id={"select-checkbox-checkin-" + i}
                            checked={isSelected(ticket)}
                            onChange={(e) =>
                              selectRecord(ticket, e?.target?.checked)
                            }
                            type="checkbox"
                            className="cp checkin-checkout-ticket-checkbox"
                          />
                          <span class="card-title fs-13 d-block mb-0">
                            #:{" "}
                            {ticket?.ticket_number
                              ? ticket.ticket_number
                              : "N/A"}
                          </span>
                          <span class="card-title text-uppercase fs-13 mb-0 d-block">
                            Ticket Type:{" "}
                            {ticket?.title
                              ? capitalizeFirstLetter(ticket.title)
                              : "N/A"}{" "}
                          </span>
                          <span class="card-title text-uppercase fs-13 mb-0">
                            Price:{" "}
                            {ticket?.price ? ticket.price.toFixed(2) : "0.00"}
                          </span>
                        </div>
                      </div>
                    </label>
                  </div>
                ))
                : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="mt-4">
          <Button
            onClick={() => {
              setTicketModal(false);
            }}
            appearance="subtle"
          >
            Close
          </Button>
          <Button appearance="primary" type="button" onClick={handleTicketNext}>
            Next
          </Button>
          <div></div>
        </Modal.Footer>
      </Modal>

      <Modal
        backdrop="static"
        size="full"
        className="invitation-modal modal-height-auto"
        open={isModalVisible}
        onClose={closeModal}
      >
        <Modal.Header>
          <Modal.Title>Questions Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table
            id="questions-table"
            className="table table-bordered table-hover"
          >
            <thead>
              <tr>
                <th>Question</th>
                <th>Question Type</th>
                <th>Is Required</th>
                <th>Answer</th>
              </tr>
            </thead>
            <tbody>
              {selectedQuestion?.map((question, index) => (
                <tr key={index}>
                  <td>
                    {question?.question_title
                      ? question?.question_title
                      : "N/A"}
                  </td>
                  <td>
                    {question?.question_type
                      ? SpecialCharacter(
                        capitalizeFirstLetterOfEachWord(
                          question?.question_type
                        )
                      )
                      : "N/A"}
                  </td>
                  <td>
                    {question?.is_required === "true" ||
                      question?.is_required === true
                      ? "Yes"
                      : "No"}
                  </td>
                  <td>
                    <ShowAnswer
                      question={question}
                      setVideoPlaySource={playVideo}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} appearance="subtle">
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        backdrop="static"
        className="invitation-modal modal-height-auto"
        open={Boolean(videoPlaySource)}
        onClose={stopVideoPlayer}
      >
        <Modal.Header>
          <Modal.Title>
            Playing : {getFileNameFromURL(videoPlaySource)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {videoPlaySource ? (
            <VideoPlayer src={videoPlaySource} type={videoSourceType} />
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewTicketDetails;
