import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import toHtml from 'html-react-parser'
// import { formateDate } from '../../helpers/commonFunctions'
import * as questionService from '../../services/questions';
import Breadcrums from '../../common/breadcrumbs';
import Loader from '../../common/loader'
import { SpecialCharacter, formateDate, removeSpecialCharacter, showStatus } from '../../../../utils/commonfunction';


const QuestionView = (props) => {
    const navigate = useNavigate()
    const params = useParams()
    const [showdefault, setShowDefault] = useState({})
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Question sets", url: "/admin/question-settings/sets/list/1" }, { title: "View", url: `/admin/question-settings/sets/view/${showdefault?.questionsets_data?._id}?page=1&survey_page=1` }, { title: "Questions", url: `/admin/question-settings/sets/view/${showdefault?.questionsets_data?._id}?page=1&survey_page=1` }, { title: "View", url: "" }]

    useEffect(() => {
        questionService.Details(params.id).then(response => {
            console.log("response",response)
            setShowDefault(response && response.data ? response.data : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [params.id])


    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div>
                                <h6 className="main-content-label mb-3">View Question</h6>
                            </div>
                            {showdefault && Object.keys(showdefault).length > 0 ? <div className='row'>
                                <div className="col-lg-12 form-group">
                                    <table id="simple-table" className="table  table-bordered table-hover">
                                        <tbody>
                                            <tr>
                                                <th>Title</th>
                                                <td>{showdefault && showdefault.title ? showdefault.title : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Title In German</th>
                                                <td>{showdefault && showdefault.title_de ? showdefault.title_de : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Column Name</th>
                                                <td>{showdefault && showdefault.column_name ? showdefault.column_name : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Question Set</th>
                                                <td>{showdefault && showdefault.questionsets_data && showdefault.questionsets_data.title ? showdefault.questionsets_data.title : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Question Type</th>
                                                <td className="text-capitalize">{showdefault && showdefault.question_type ? SpecialCharacter(showdefault.question_type) : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Options</th>
                                                <td>
                                                    {
                                                        showdefault && showdefault.options && showdefault.options.length > 0 ?
                                                            showdefault.options.map((item, index) => {
                                                                return (
                                                                    <span key={index}
                                                                        className={index === 0 ? "badge badge-pill badge-primary-light text-capitalize" : "badge badge-pill badge-primary-light text-capitalize ms-2"}
                                                                    >{item}</span>
                                                                )
                                                            })
                                                            : "N/A"
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Options German</th>
                                                <td>
                                                    {
                                                        showdefault && showdefault.options_de && showdefault.options_de.length > 0 ?
                                                            showdefault.options_de.map((item, index) => {
                                                                return (
                                                                    <span key={index}
                                                                        className={index === 0 ? "badge badge-pill badge-primary-light text-capitalize" : "badge badge-pill badge-primary-light text-capitalize ms-2"}
                                                                    >{item}</span>
                                                                )
                                                            })
                                                            : "N/A"
                                                    }
                                                </td>
                                            </tr>
                                            {showdefault && showdefault.question_type && showdefault.question_type === "emoji" ?
                                            <tr>
                                                <th>Emojis</th>
                                                <td>
                                                    {showdefault && showdefault?.emoji_options ? showdefault.emoji_options : "N/A"}
                                                        
                                                </td>
                                            </tr>
                                            : null}
                                            <tr>
                                                <th>Is Required</th>
                                                <td className="text-capitalize">{(showdefault &&  showdefault?.is_required === "true") || showdefault?.is_required === true ? "Yes" : "No"}</td>
                                            </tr>
                                            <tr>
                                                <th>Is Dedicated Question</th>
                                                <td className="text-capitalize">{(showdefault && showdefault?.is_dedicated_questions === "true") || showdefault?.is_dedicated_questions === true ? "Yes" : "No"}</td>
                                            </tr>
                                            <tr>
                                                <th>Is Name Badge Question</th>
                                                <td className="text-capitalize">{(showdefault && showdefault?.is_name_badge_question === "true") || showdefault?.is_name_badge_question === true ? "Yes" : "No"}</td>
                                            </tr>
                                            <tr>
                                                <th>Created Date</th>
                                                <td>{showdefault && showdefault.createdAt ? (formateDate(showdefault.createdAt)) : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Status</th>
                                                <td>{showdefault && showdefault.status === 0 ? toHtml(showStatus(showdefault.status)) : toHtml(showStatus(showdefault.status))}</td>
                                            </tr>
                                            <tr>
                                                <th>Modified Date</th>
                                                <td>{showdefault && showdefault.updatedAt ? formateDate(showdefault.updatedAt) : "N/A"}</td>
                                            </tr>

                                            <tr>
                                                <td colSpan={2}>
                                                    <div dangerouslySetInnerHTML={{ __html: showdefault && showdefault.description ? showdefault.description : "N/A" }}></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div dangerouslySetInnerHTML={{ __html: showdefault && showdefault.description_de ? showdefault.description_de : "N/A" }}></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                   
                                    <div className="mt-5">
                                        <button className="btn ripple btn-dark" type='button' onClick={() => navigate(-1)}>
                                            <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                            Back
                                        </button>
                                    </div>
                                </div>
                            </div> : <Loader />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuestionView
